export interface INavigationRoute {
  name: string
  displayName: string
  meta: { icon: string }
  children?: INavigationRoute[]
}

export default {
  root: {
    name: '/',
    displayName: 'navigationRoutes.home',
  },
  routes: [
    {
      name: 'dashboard',
      displayName: 'menu.dashboard',
      meta: {
        icon: 'vuestic-iconset-dashboard',
      },
    },
    {
      name: 'users',
      displayName: 'menu.users',
      meta: {
        icon: 'group',
      },
    },
    {
      name: 'wallets',
      displayName: 'menu.wallets',
      meta: {
        icon: 'account_balance_wallet',
      },
    },
    {
      name: 'crypto-assets',
      displayName: 'menu.crypto-assets',
      meta: {
        icon: 'currency_bitcoin',
      },
      children: [
        {
          name: 'portfolio',
          displayName: 'menu.portfolio',
        },
        {
          name: 'operations',
          displayName: 'menu.operations',
        },
        {
          name: 'reports',
          displayName: 'menu.reports',
        },
        {
          name: 'imports',
          displayName: 'menu.imports',
        },
      ],
    },
    {
      name: 'statements',
      displayName: 'menu.statements',
      meta: {
        icon: 'description',
      },
    },
    // {
    //   name: 'projects',
    //   displayName: 'menu.projects',
    //   meta: {
    //     icon: 'folder_shared',
    //   },
    // },
    {
      name: 'payments',
      displayName: 'menu.payments',
      meta: {
        icon: 'credit_card',
      },
      children: [
        // {
        //   name: 'payment-methods',
        //   displayName: 'menu.payment-methods',
        // },
        {
          name: 'pricing-plans',
          displayName: 'menu.pricing-plans',
        },
        // {
        //   name: 'billing',
        //   displayName: 'menu.billing',
        // },
      ],
    },
    // {
    //   name: 'auth',
    //   displayName: 'menu.auth',
    //   meta: {
    //     icon: 'login',
    //   },
    //   children: [
    //     {
    //       name: 'login',
    //       displayName: 'menu.login',
    //     },
    //     {
    //       name: 'signup',
    //       displayName: 'menu.signup',
    //     },
    //     {
    //       name: 'recover-password',
    //       displayName: 'menu.recover-password',
    //     },
    //   ],
    // },
    // {
    //   name: '404',
    //   displayName: 'menu.404',
    //   meta: {
    //     icon: 'vuestic-iconset-files',
    //   },
    // },
    {
      name: 'preferences',
      displayName: 'menu.preferences',
      meta: {
        icon: 'manage_accounts',
      },
    },
    {
      name: 'settings',
      displayName: 'menu.settings',
      meta: {
        icon: 'settings',
      },
    },
    {
      name: 'faq',
      displayName: 'menu.faq',
      meta: {
        icon: 'quiz',
      },
    },
  ] as INavigationRoute[],
}
